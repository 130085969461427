// @import '../../../scss/vars.scss';

form {
	.form-field {

		.uk-input, .uk-select:not([multiple]):not([size]) {
			height: 36px;
		}

		.uk-select,
		.uk-input,
		.uk-textarea,
		.flatpickr-input {
			background-color: transparent;
			padding: 3px;
			height: 36px;
			border: 1px solid transparent;
			border-bottom: 1px solid #c0c0c0;
			color: #000;
			&[multiple] {
				height: auto;
				min-height: 100px;
				border: 1px solid #c0c0c0;
			}
		}

		.uk-textarea {
			border: 1px solid #d0d0d0;
			border-bottom: 1px solid #c0c0c0;
			height: 100px;
			font-size: 0.8rem;
		}

		.label {
			font-size: 0.8rem;
			margin-top: 2px;
			margin-bottom: 10px;
			padding: 0 3px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			.icons {
				display: inline-block;
				vertical-align: top;
				padding-top: 2px;
				> span {
					margin-right: 3px;
				}
				.icon-success {
					color: green;
					display: none;
				}
				.icon-error {
					color: red;
					display: none;
				}
				.icon-busy {
					display: none;
				}
			}
			.label-text {
				display: inline-block;
				white-space: normal;
			}
			.error-list {
				display: none;
				color: red;
				white-space: normal;
			}
		}

		&.error {
			.uk-select,
			.uk-input,
			.uk-textarea {
				border-bottom-color: red;
			}
			.label {
				.label-text {
					display: none;
				}
				.icon-error {
					display: block;
				}
				.error-list {
					display: inline-block;
				}
			}
		}

		&.success {
			.uk-select,
			.uk-input,
			.uk-textarea {
				border-bottom-color: green;
				&:focus {
					border-bottom-color: var(--color-secondary);
				}
			}
			.label {
				.icon-success {
					display: block;
				}
			}
		}

		&.busy {
			.label {
				.label-text {
					opacity: 0.5;
				}
				.icons span {
					display: none;
					&.icon-busy {
						display: block;
					}
				}
			}
		}

		// &.short {
		// 	.label {
		// 		margin: 0;
		// 		padding: 0;
		// 	}
		// }

		&.apple-switch {
			label, span {
				display: inline-block;
				padding-top: 7px;
				line-height: 125%;
			}
			input {
				position: relative;
				appearance: none;
				outline: none;
				width: 50px;
				height: 30px;
				background-color: #ffffff;
				border: 1px solid #d0d0d0;
				border-radius: 50px;
				box-shadow: inset -20px 0 0 0 #ffffff;
				transition-duration: 200ms;
				&:after {
					content: "";
					position: absolute;
					top: 1px;
					left: 1px;
					width: 26px;
					height: 26px;
					background-color: transparent;
					border-radius: 50%;
					box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
				}
				&:checked {
					border-color: green;
					box-shadow: inset 20px 0 0 0 green;
					&[disabled] {
						border-color: #a0a0a0;
						box-shadow: inset 20px 0 0 0 #a0a0a0;
					}
				}
				&:checked:after {
					left: 20px;
					box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
				}
			}
		}
		&.microsite {
			.uk-select,
			.uk-input,
			.uk-textarea {
				border: 1px solid #d0d0d0;
				border-radius: 5px;
			}

		}

	}

	&.nav-form {
		.form-field {
			.uk-input {
				color: #fff;
				border-bottom-color: 1px solid rgba(255,255,255,.6);
			}
			.label {
				color: rgba(255,255,255,.6);
				.icons {
					display: none;
				}
			}
		}
	}
}
