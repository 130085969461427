@import './vars.scss';
@import './fonts.scss';
@import '../modules/forms/scss/forms.scss';

// buttons
sb-ui-button {
	+ sb-ui-button {
		margin-left: 5px;
	}
}

.avatar {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 3px solid #fff;
	-webkit-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.68);
	-moz-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.68);
	box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.68);
	&.avatar-small {
		width: 60px;
		height: 60px;
		border: 2px solid #fff;
	}
	&.avatar-mini {
		width: 30px;
		height: 30px;
		border: 1px solid #fff;
	}
}
.no-avatar {
	box-sizing: border-box;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 3px solid rgba(255, 255, 255, 0.5);
	&.avatar-small {
		width: 60px;
		height: 60px;
		border: 2px solid rgba(255, 255, 255, 0.5);
	}
}

.uk-accordion-title {
	font-size: 0.8rem;
	text-transform: uppercase;
	color: #808080;
	border-bottom: 1px solid #808080;
	padding-bottom: 3px;
}

.stat-box {
  padding: 1vw;
  background-color: rgba(0,0,0,.5);
  margin-bottom: 1vh;
}
