:root {
    --background-default: #404040;
    --background-success: #b4e6b4;
    --background-error: #f2cbcb;
    --color-primary: #404040;
    --color-secondary: #808080;
    --color-success: #008000;
    --color-warn: #ffc700;
    --color-error: #800000;
    @media (max-width: 1024px) {
        --genius-candidate-width: 350px;
    }
    @media (min-width: 1025px) {
        --genius-candidate-width: 450px;
    }
    --bgimage-app: none;
    --bgimage-login: none;
    --bgimage-no-avatar: none;

    --kn-light: #0099DA;
    --kn-dark: #003369;
}
