/* open-sans-300 - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-regular - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600 - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-800 - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/assets/layout/fonts/open-sans-v15-latin-ext_latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  @global-font-family: Arial, 'Open Sans', 'Helvetica Neue', Verdana, sans-serif;
